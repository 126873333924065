import React from "react"
import { graphql } from "gatsby"
import WebsiteMeta from "../../components/WebsiteMeta"
import styled from "styled-components"
import Logo from "../../components/svg/Logo"

export default function sanityEditionScreenshotTemplate({ data }) {
  const edition = data.sanityEdition

  if (edition.status !== "approved" && edition.status !== "archived") {
    return null
  }

  return (
    <>
      <WebsiteMeta title={edition.name} screenshot />
      <Screenshot>
        <Header>
          <Inner>
            <Title>{edition.name} Edition</Title>
          </Inner>
        </Header>
        <Footer>
          <div>
            <PublishDate>{edition.release}</PublishDate>
          </div>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </Footer>
      </Screenshot>
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    sanityEdition(_id: { eq: $id }) {
      name
      status
      release(formatString: "MMMM D, YYYY")
    }
  }
`

const Screenshot = styled.article`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 1200px;
  max-height: 630px;
  font-family: var(--font-sans-serif);

  * {
    margin: 0;
  }
`

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 3.5rem;
  background: var(--maroon);
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  color: var(--light100);
  font-family: var(--font-serif);
  font-size: 6em;
`

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 3.5rem;
  background: var(--maroon);
`

const PublishDate = styled.p`
  color: rgba(var(--white-rgb), 0.75);
  font-size: 3em;
  font-weight: bold;
`

const LogoWrapper = styled.div`
  svg {
    fill: rgba(var(--white-rgb), 0.75);
  }
`
